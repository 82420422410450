require('./bootstrap');
require('alpinejs');

import './gallery';

document
    .querySelector(".hamburger")
    .addEventListener("click", () => {
        document.querySelector(".hamburger").classList.toggle("hamburger--open");
        document
            .querySelector(".nav")
            .classList.toggle("nav--active");
    });
document
    .querySelector(".nav__close")
    .addEventListener("click", () => {
        document.querySelector(".hamburger").classList.toggle("hamburger--open");
        document
            .querySelector(".nav")
            .classList.toggle("nav--active");
        document
            .querySelector(".lang-select")
            .classList.toggle("lang-select--active");
    });
